@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/48a79ab3c155c297-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/48a79ab3c155c297-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/8930f07ec2921026-s.p.ttf) format('truetype');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/5658b06f894fed15-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/5658b06f894fed15-s.p.ttf) format('truetype');
font-display: swap;
font-weight: bold;
font-style: normal;
}

@font-face {
font-family: '__Ivy_eceea5';
src: url(/_next/static/media/5658b06f894fed15-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__Ivy_Fallback_eceea5';src: local("Arial");ascent-override: 100.92%;descent-override: 24.29%;line-gap-override: 2.94%;size-adjust: 98.79%
}.__className_eceea5 {font-family: '__Ivy_eceea5', '__Ivy_Fallback_eceea5'
}

